<template>
  <div class="operationsmanage-container">
    <span style="float: right; margin: 10px 20px 10px 0">
          <el-input
              v-model.trim="queryForm.name"
              clearable
              style="width: 300px"
              placeholder="请输入活动名称"
              size="small"
          />
          <el-button icon="el-icon-search" @click="queryData" type="primary" size="small"
                     style="margin: 0 20px 0 5px">
            查询
          </el-button>
      </span>
    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column align="center" label="日期">
        <template slot-scope="scope">
          {{ scope.row.create_at | formatDate }}
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="user_id"
          label="会员">
      </el-table-column>
      <el-table-column
          align="center"
          prop="activity"
          label="活动">
      </el-table-column>
      <el-table-column
          align="center"
          prop="coupon_id"
          label="优惠券">
      </el-table-column>
      <el-table-column
          align="center"
          prop="money_balance"
          label="余额">
      </el-table-column>
      <el-table-column
          align="center"
          prop="consume"
          label="积分">
      </el-table-column>
    </el-table>
    <el-pagination
        style="text-align: center;"
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </div>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      query_time: [],
      activeName: '1',
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
        start_date: '',
        end_date: '',
      },
      current: 0,
      list: [
        {
          id: '1',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          start_at: '1647584613',
          end_at: '1647584613',
          site: [
            {name: '早上'},
            {name: '中午'},
            {name: '下午'},
          ],
          merchant_id: '1',
          is_check: '0',
        },
        {
          id: '2',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          start_at: '1647584613',
          end_at: '1647584613',
          site: [
            {name: '早上'},
            {name: '中午'},
          ],
          merchant_id: '1',
          is_check: '1',
        },
        {
          id: '3',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '1',
          create_at: '1647584613',
          start_at: '1647584613',
          end_at: '1647584613',
          site: [
            {name: '早上'},
          ],
          merchant_id: '1',
          is_check: '2',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const {code, message} = await doDelete({id: row.id})
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const {code, message} = await doDelete({id})
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const {data} = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      const {list} = data
      for (let i = 0; i < list.length; i++) {
        list[i]['state'] = list[i]['state'].toString()
      }
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },

    // 状态
    formatterState: function (row) {
      return row.state == '0' ? '进行中'
          : row.state == '1' ? '已结束'
              : '暂无'
    },
  }
}

</script>
<style>
.button {
  margin-top: 20px;
}

.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
